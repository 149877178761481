import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout.component"
import Header from "../components/header.component"
import { RichText } from "prismic-reactjs"
import { Image } from "../components/common/image.component"
import { CustomLink } from "../components/common/custom-link.component"
import { PlainStructuredText } from "../components/common/plain-structured-text.component"
import { Slices } from "../components/slices.component"
import { linkFragment } from "../link-resolver"
import SEO from "../components/common/seo.component"

const Portfolio = ({ data, uri }) => {
  const page = data.prismic.allPortfolios.edges[0]
  if (page) {
    const stories = data.prismic.allSuccess_storys.edges
    return (
      <Layout>
        <SEO
          title={`ANKO | ${page.node.page_title || page.node.title[0].text}`}
          description={page.node.description || page.node.text}
          keywords={page.node.keywords}
          image={page.node.preview_image}
          lang={page.node._meta.lang}
          canonical={uri}
        />
        <div className="container">
          <Header theme="light" />
        </div>
        <div className="container">
          <div className="portfolio-header text-center text-dark-blue mx-auto mb-5">
            <RichText render={page.node.text_content} />
          </div>
          <div className="row mb-5">
            {stories.map(story => {
              const { title, image, text_content, _meta } = story.node
              return (
                <div className="col-12 col-lg-6 col-xl-4  mb-5">
                  <div className="card">
                    <Image
                      className="portfolio-img card-img-top"
                      image={image}
                    />
                    <div className="portfolio-card-body">
                      <div className="portfolio-card-text">
                        <div className="mb-2">
                          <CustomLink
                            link={{ _meta, _linkType: "Link.document" }}
                            className="font-semi-bold text-dark-blue font-22"
                          >
                            <PlainStructuredText structuredText={title} />
                          </CustomLink>
                        </div>
                        <div className="text-secondary">
                          <div className="portfolio-text-description mb-5">
                            <RichText render={text_content} />
                          </div>
                        </div>
                      </div>
                      <div className="text-center">
                        <CustomLink
                          link={{ _meta, _linkType: "Link.document" }}
                          className="btn btn-primary btn-sm mx-auto btn-portfolio"
                        >
                          Read More
                        </CustomLink>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <Slices body={page.node.body} />
      </Layout>
    )
  }
  return null
}

Portfolio.fragments = [linkFragment]

export default Portfolio

export const query = graphql`
  query portfolioQuery {
    prismic {
      allPortfolios {
        edges {
          node {
            text_content
            page_title
            description
            keywords {
              keyword
            }
            preview_image
            _meta {
              lang
              uid
              type
            }
            body {
              ... on PRISMIC_PortfolioBodyGallery {
                type
                label
                primary {
                  anchor
                  bgColor
                  bgImage
                  text
                  title
                }
                fields {
                  image
                  text
                  title
                }
              }
              ... on PRISMIC_PortfolioBodyPricing_plans {
                type
                label
                fields {
                  price_units
                  plan_price
                  plan_name
                  plan_features
                  link_text
                  link_style
                  link {
                    ...link
                  }
                  is_free_plan
                }
                primary {
                  bg_image
                  bg_color
                  title
                  text
                }
              }
              ... on PRISMIC_PortfolioBodyText {
                type
                label
                primary {
                  text
                  bg_color
                  bg_image
                }
              }
              ... on PRISMIC_PortfolioBodyFeature {
                type
                label
                primary {
                  bg_color
                  bg_image
                  text
                }
                fields {
                  image
                  link_style
                  link_text
                  text
                  title
                  link {
                    ...link
                  }
                }
              }
              ... on PRISMIC_PortfolioBodyBlock_with_text_and_image {
                label
                type
                primary {
                  bg_color
                  bg_image
                  min_height
                  title
                  text
                  image
                  link {
                    ...link
                  }
                  link_style
                  link_text
                }
              }
              ... on PRISMIC_PortfolioBodyForm {
                type
                label
                primary {
                  bg_color
                  bg_image
                  form_script
                  form_url
                  text
                }
              }
            }
          }
        }
      }
      allSuccess_storys {
        edges {
          node {
            _meta {
              lang
              type
              uid
            }
            title
            text_content
            image
          }
        }
      }
    }
  }
`
